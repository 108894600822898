// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function() {};
	var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
})();
// -----------------------------------------------------------------------------------------

// @codekit-prepend "../../../../../../node_modules/jquery/dist/jquery.js" quiet;
// @codekit-prepend "../../../../../../node_modules/jquery-migrate/dist/jquery-migrate.js" quiet;

// lazy load with lazysizes
// https://github.com/aFarkas/lazysizes/blob/gh-pages/README.md
// @codekit-prepend "../../../../../../node_modules/lazysizes/lazysizes.js" quiet;

// @codekit-prepend "../../../../../../node_modules/jquery-validation/dist/jquery.validate.js" quiet;
// @codekit-prepend "../../../../../../node_modules/jquery-validation/dist/additional-methods.js" quiet;

// @codekit-prepend "../../../../../../node_modules/aos/dist/aos.js" quiet;

// @codekit-prepend "../magnific-popup/dist/jquery.magnific-popup.js" quiet;

// -----------------------------------------------------------------------------------------
// jQuery.noConflict();

// (function($) {
jQuery(function($) {
	// ---------------------------------------------------------------------------------------
	// mobile size function -- :before to class
	// transform the css :after mobile to a more usable class mobile
	function classMobile() {
		var size = window.getComputedStyle(document.body, ':before').getPropertyValue('content');

		size = size.replace(/"/g, '');

		if (size == 'mobile') {
			$('body').addClass('mobile');
			$('body').removeClass('screen');
		} else if (size == 'screen') {
			$('body').addClass('screen');
			$('body').removeClass('mobile');
		} else {
			$('body').removeClass('mobile');
			$('body').removeClass('screen');
		}

		var size = window.getComputedStyle(document.body, ':after').getPropertyValue('content');

		size = size.replace(/"/g, '');

		if (size == 'burger') {
			$('body').addClass('burger');
			$('body').removeClass('non-burger');
		} else if (size == 'non-burger') {
			$('body').addClass('non-burger');
			$('body').removeClass('burger');
		} else {
			$('body').removeClass('burger');
			$('body').removeClass('non-burger');
		}
	}

	classMobile();
	// -----------------------------------------------------------------------------------------

	// ------------------------------------------------------------------------------------------------
	$(document).on('click', '.hamburger', function() {
		$('.hamburger').toggleClass('is-active');
		$('body').toggleClass('bodyfix');
		$('header nav').toggleClass('is-active');
		// console.log('click burger');
	});

	// close the menu with ESC key
	$(document).on('keyup', function(evt) {
		if (evt.keyCode == 27) {
			// burger navigation
			$('body').removeClass('bodyfix');
			$('.hamburger').removeClass('is-active');
			$('header nav').removeClass('is-active');

			//console.log('esc close menu');
		}
	});

	//-------------------------------------------------------------------------------------------------------------------------
	// sticky navigation

	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('header nav').outerHeight();

	$(window).on('scroll', function(event) {
		didScroll = true;
	});

	var is_home = false;
	var header_height = $('header').outerHeight();

	// console.log('header_height ' + header_height);

	if (typeof comment === 'undefined') {
		var header_height = 1;
	} else if (comment === null) {
		var header_height = 1;
	}

	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 300);

	function hasScrolled() {
		var st = $(this).scrollTop();

		// Make scroll more than delta
		if (Math.abs(lastScrollTop - st) <= delta) return;

		// If scrolled down and past the navbar, add class .nav-up.
		if (st > lastScrollTop && st > navbarHeight) {
			// Scroll Down
			$('header')
				.removeClass('nav-up')
				.addClass('nav-down');
		} else {
			// Scroll Up
			if (st + $(window).height() < $(document).height()) {
				$('header')
					.removeClass('nav-down')
					.addClass('nav-up');
			}

			// console.log(st);
			// hide the sticky nav before reaching the pagehead
			if (st < header_height) {
				//$('header').removeClass('nav-down').removeClass('nav-up');
				$('header').addClass('on-top');
			} else {
				$('header').removeClass('on-top');
			}
		}

		lastScrollTop = st;
	}

	//-------------------------------------------------------------------------------------------------------------------------
	function fbq_track_url() {
		var url = window.location;
		url = url.href.split('?')[0];
		url = url.replace(/\/$/, '');
		url = url + '/erfolgreich';

		// console.log('fbq url: ' + fbq_track_url());

		return url;
	}

	//-------------------------------------------------------------------------------------------------------------------------
	// appointment request

	//-----------------------------------------------------------------------------
	// appointment request function

	function appointment_request(date, time, firstname, surename, phone, accessibility, age, size, weight, goal, health, intolerances_true_false, intolerances, diets, bmi, weight_loss, location, preference) {
		// console.log('ajax appointment request: start');

		$('#appointment-from .loader').show();

		// google conversion tracking
		if ((typeof gtag_report_conversion !== 'undefined' || typeof gtag_report_conversion === 'function') && typeof dataLayer !== 'undefined') {
			gtag_report_conversion();
			// console.log('check gtag_report_conversion');
		}

		// facebook / meta tracking
		if (typeof fbq !== 'undefined' || typeof fbq === 'function') {
			// fbq('track', 'Lead');
			// fbq('track', 'Lead', { url: fbq_track_url() });
			fbq('track', 'Lead', { event_source_url: fbq_track_url() });
			// console.log('fbq url: ' + fbq_track_url());
		}

		// microsoft event goals tracking
		if ((typeof uetq === 'function' || typeof uetq !== 'undefined') && (typeof uet_report_conversion === 'function' || typeof uet_report_conversion !== 'undefined')) {
			// console.log('uet_report_conversion');
			uet_report_conversion();
		}

		$.ajax({
			type: 'POST',
			url: easy_ajax_object.ajax_url,
			data: {
				action: 'appointment_request',
				date: date,
				time: time,
				firstname: firstname,
				surename: surename,
				phone: phone,
				accessibility: accessibility,
				age: age,
				size: size,
				weight: weight,
				goal: goal,
				health: health,
				intolerances_true_false: intolerances_true_false,
				intolerances: intolerances,
				diets: diets,
				bmi: bmi,
				weight_loss: weight_loss,
				location: location,
				preference: preference,
				nonce: easy_ajax_object.nonce,
			},
		}).done(function(data) {
			// console.log('ajax appointment request: done');

			// get the json from the ajax response and split it up in to separate vars
			var data_all = JSON.parse(data);
			var response = data_all.response;
			var response_headline = data_all.response_headline;
			var response_copy = data_all.response_copy;

			// console.log('response: ' + response);
			// console.log('response headline: ' + response_headline);
			// console.log('response_copy: ' + response_copy);

			$('#appointment-from .loader').hide();

			// scroll to top of the for
			var headerHeight = $('header').outerHeight();
			var $targetEle = $('#appointment-from');
			$('html, body').animate({ scrollTop: $targetEle.offset().top - headerHeight - 50 }, 300, 'linear');

			$('#step-response').addClass(response);
			$('#step-response h2').html(response_headline);
			$('#step-response .copy p').html(response_copy);
		});
	}
	//-----------------------------------------------------------------------------

	//-----------------------------------------------------------------------------
	// set cursor in the in put by click on the label
	/* 	$('#appointment-from').on('click', 'label', function() {
		$(this)
			.find('input')
			.focus();
	}); */
	//-----------------------------------------------------------------------------

	/* const button = $("label[for=date]);
	const dateInput = $("input[type=date]");
	
	button.addEventListener("click", () => {
		try {
			dateInput.showPicker();
			// A date picker is shown.
		} catch (error) {
			// Use external library when this fails.
		}
	}); */

	//-----------------------------------------------------------------------------
	// validation with jquery validation plugin
	// https://jqueryvalidation.org/documentation/
	// https://gist.github.com/rfessler/3940947

	jQuery.validator.addMethod('weekDay', function(value, element, params) {
		// console.log('date:' + value);
		var date = new Date(value);

		day = date.getDay();
		return day != 0 && day != 6;
	});

	jQuery.validator.addMethod('phoneMatch', function(value, element, params) {
		var phone = $('#phone').val().replace(/\s/g, ''); // remove spaces
		var phoneRepeat = $('#phone-repeat').val().replace(/\s/g, ''); // remove spaces
		return phone === phoneRepeat;
	}, $('#phone').data('msg'));

	var validate = $('#appointment-from').validate({
		debug: false,
		errorClass: 'error-message',
		errorElement: 'span',
		ignore: '.ignore',
		submitHandler: function() {
			// console.log('successful submit');
			return false;
		},

		rules: {
			date: {
				required: false,
				// date: true,
				weekDay: true,
			},

			location: {
				required: true,
				//	select: 'required',
			},

			phone: {
				required: true,
				pattern: $('#phone').attr('pattern'), // use the regex pattern from pattern attribute
			},

			'phone-repeat': {
				required: true,
				pattern: $('#phone-repeat').attr('pattern'), // use the regex pattern from pattern attribute
				phoneMatch: true,
			},
		},

		highlight: function(element, errorClass, validClass) {
			$(element)
				.addClass(errorClass)
				.removeClass(validClass);
			$(element.form)
				.find('label[for=' + element.id + ']')
				.addClass(errorClass);
			$(element)
				.closest('.field-wrapper')
				.addClass('has-error');
			$(element)
				.closest('fieldset')
				.find('.button')
				.addClass('blocked')
				.prop('disabled', true);
		},
		unhighlight: function(element, errorClass, validClass) {
			$(element)
				.removeClass(errorClass)
				.addClass(validClass);
			$(element.form)
				.find('label[for=' + element.id + ']')
				.removeClass(errorClass);
			$(element)
				.closest('.field-wrapper')
				.removeClass('has-error');
			$(element)
				.closest('fieldset')
				.find('.button')
				.removeClass('blocked')
				.prop('disabled', false);
		},
	});



	// ----------------------------------------------
	// control the width and padding of the input for floating labels
	function floatingLabelPaddingWidth() {
		$('#appointment-from input[type="date"], #appointment-from input[type="time"]').each(function() {
			var labelWidth = $(this)
				.parent()
				.innerWidth();
			// setconsole.log('labelWidth: ' + labelWidth);

			var floatLabelWidth = $(this)
				.parent()
				.find('.floating-label')
				.outerWidth();
			// console.log('floatLabelWidth: ' + floatLabelWidth);

			var padding = floatLabelWidth + 45; // + 16px (2rem) for the before pseudo-element
			var inputWidth = labelWidth - padding;

			// console.log('inputWidth: ' + inputWidth);

			$(this).css('padding-left', padding);
			$(this).css('width', inputWidth);
		});
	}

	// ----------------------------------------------
	// step 1
	// $('#date').focus();
	// $('#time').focus();
	// $('#accessibility').focus();
	function appointment_scroll_top() {
		var headerHeight = $('header').outerHeight();
		var $targetEle = $('.appointment-from');
		$('html, body').animate({ scrollTop: $targetEle.offset().top - headerHeight - 28 }, 300, 'linear');
	}

	$('#step-date').on('click', '.next', function(e) {
		e.preventDefault(e);

		// the form on single location pages don’t have a location select menu
		// check val() only on the from with select menu
		if ($('#location').length) {
			validate.element('#location');

			var if_statement = $('#step-date').valid() && $('#location').val();
		} else {
			var if_statement = $('#step-date').valid();
		}

		if (if_statement) {
			// console.log('step date is valid');
			$('#step-date')
				.addClass('hidden')
				.removeClass('active');
			$('#step-contact')
				.addClass('active')
				.removeClass('hidden');

			floatingLabelPaddingWidth();

			appointment_scroll_top();

			$('.screen #step-contact input[name="firstname"]').focus();
		} else {
			// console.log('step date is not valid');
		}
	});

	$('#step-contact').on('click', '.back', function(e) {
		e.preventDefault(e);
		$('#step-contact')
			.removeClass('active')
			.addClass('hidden');
		$('#step-date')
			.addClass('active')
			.removeClass('hidden');

		floatingLabelPaddingWidth();

		appointment_scroll_top();
	});

	// appointment submit
	//	$(document).on('submit', '#appointment-from', function(e) {
	$('#appointment-from').on('click', 'input[type="submit"]', function(e) {
		e.preventDefault();

		var date = $("input[name~='date']").val();
		var time = $('#time  option:selected').val();
		var firstname = $("input[name='firstname']").val();
		var surename = $("input[name='surename']").val();
		var phone = $("input[name='phone']").val();
		var accessibility = $('#accessibility option:selected').val();

		// values from figure check
		var age = $("input[name~='age']").val();
		var size = $("input[name~='size']").val();
		var weight = $("input[name~='weight']").val();
		var goal = $("input[name~='goal']").val();
		var health = $("input[name~='health']").val();
		var intolerances_true_false = $("input[name~='intolerances_true_false']").val();
		var intolerances = $("input[name~='intolerances']").val();
		var diets = $("input[name~='diets']").val();
		var bmi = $("input[name~='bmi']").val();
		var weight_loss = $("input[name~='weight_loss']").val();
		var preference = $("input[name~='preference']").val();
		var location_select = $('#location option:selected').val();
		var location_hidden = $("input[name~='location']").val();

		var location = '';
		if (location_hidden != null) {
			location = location_hidden;
		} else if (location_select != null) {
			location = location_select;
		}

		if ($('#appointment-from').valid()) {
			appointment_request(date, time, firstname, surename, phone, accessibility, age, size, weight, goal, health, intolerances_true_false, intolerances, diets, bmi, weight_loss, location, preference);
			console.log('form is valid');

			$('#step-contact')
				.removeClass('active')
				.addClass('hidden');
			$('#step-response')
				.addClass('active')
				.removeClass('hidden');
		} else {
			console.log('form is not valid');
		}
	});

	$('#appointment-from').on('change', '#location', function() {
		validate.element('#location');

		if ($('#location').val()) {
			$('#location')
				.parent()
				.addClass('has-value');
		} else {
			$('#location')
				.parent()
				.removeClass('has-value');
		}
	});

	$('#appointment-from').on('change', '#time', function() {
		validate.element('#time');

		if ($('#time').val()) {
			$('#time')
				.parent()
				.addClass('has-value');

			$('#time')
				.prev('label')
				.find('.label-content')
				.hide();
		} else {
			$('#time')
				.parent()
				.removeClass('has-value');

			$('#time')
				.prev('label')
				.find('.label-content')
				.show();
		}
	});

	$('#appointment-from').on('change', '#accessibility', function() {
		//validate.element('#accessibility');

		if ($('#accessibility').val()) {
			$('#accessibility')
				.parent()
				.addClass('has-value');

			$('#accessibility')
				.prev('label')
				.find('.label-content')
				.hide();
		} else {
			$('#accessibility')
				.parent()
				.removeClass('has-value');

			$('#accessibility')
				.prev('label')
				.find('.label-content')
				.show();
		}
	});

	$('#appointment-from').on('change', '#location', function() {
		//validate.element('#accessibility');

		if ($('#location').val()) {
			$('#location')
				.parent()
				.addClass('has-value');

			$('#location')
				.prev('label')
				.find('.label-content')
				.hide();
		} else {
			$('#location')
				.parent()
				.removeClass('has-value');

			$('#location')
				.prev('label')
				.find('.label-content')
				.show();
		}

		var phone = $('#location option:selected').data('phone');
		var phone_number = $('#location option:selected').data('phone-number');
		var address = $('#location option:selected').data('address');

		// console.log('phone:' + phone + ' phone_number:' + phone_number + ' address:' + address);

		$('#contact')
			.find('.address')
			.remove();
		$('#contact')
			.find('.phone')
			.remove();

		if (address) {
			$('#contact').append('<div class="address">' + address + '</div>');
		}
		if (phone) {
			$('#contact').append('<div class="phone"><a href="tel:' + phone_number + '">' + phone + '</a></div>');
		}
	});

	$('#appointment-from').on('blur', 'input[type="date"], input[type="time"]', function() {
		$(this)
			.parent()
			.removeClass('in-focus');

		if ($(this).val()) {
			$(this)
				.parent()
				.addClass('has-value');
		} else {
			$(this)
				.parent()
				.removeClass('has-value');
		}

		var elemID = $(this).attr('id');
		validate.element('#' + elemID);
	});

	$('#appointment-from').on('input', 'input[name="firstname"]', function() {
		validate.element('#appointment-from input[name="firstname"]');
	});
	$('#appointment-from').on('input', 'input[name="surename"]', function() {
		validate.element('#appointment-from input[name="surename"]');
	});
	$('#appointment-from').on('input', 'input[name="phone "]', function() {
		validate.element('#appointment-from input[name="phone"]');
	});
	// $('#appointment-from').on('input', 'input[name="phone-repeat"]', function() {
	// 	validate.element('#appointment-from input[name="phone-repeat"]');
	// });

	// floating labels
	floatingLabelPaddingWidth();

	$('#appointment-from input[type="text"], #appointment-from input[type="tel"], #appointment-from input[type="date"], #appointment-from input[type="time"]').each(function() {
		if ($(this).val()) {
			$(this)
				.parent()
				.addClass('has - value');
		} else {
			$(this)
				.parent()
				.removeClass('has-value');
		}
	});

	$('#appointment-from').on('focus', 'input[type="text"], input[type="tel"], input[type="date"], input[type="time"]', function(event) {
		if ($(this).val()) {
			$(this)
				.parent()
				.addClass('has-value');
		} else {
			$(this)
				.parent()
				.removeClass('has-value');
		}

		$(this)
			.parent()
			.addClass('in-focus');
	});

	$('#appointment-from').on('blur', 'input[type="text"], input[type="tel"], input[type="date"], input[type="time"]', function() {
		if ($(this).val()) {
			$(this)
				.parent()
				.addClass('has-value');
		} else {
			$(this)
				.parent()
				.removeClass('has-value');
		}

		$(this)
			.parent()
			.removeClass('in-focus');
	});

	//-------------------------------------------------------------------------------------------------------------------------
	// figure check

	//----------------------------------------------------------------------------------
	// figure check ajax function

	function figure_check(firstname, age, size, weight, goal, health, intolerances_true_false, intolerances, diets, weight_goal, preference) {
		// console.log('ajax appointment request: start');

		$('#appointment-from .loader').show();

		// google conversion tracking
		if ((typeof gtag_report_conversion !== 'undefined' || typeof gtag_report_conversion === 'function') && typeof dataLayer !== 'undefined') {
			gtag_report_conversion();
			// console.log('check gtag_report_conversion');
		}

		// facebook / meta tracking
		if (typeof fbq !== 'undefined' || typeof fbq === 'function') {
			fbq('track', 'CompleteRegistration');
		}

		// microsoft event goals tracking
		if ((typeof uetq === 'function' || typeof uetq !== 'undefined') && (typeof uet_report_conversion === 'function' || typeof uet_report_conversion !== 'undefined')) {
			// console.log('uet_report_conversion');
			uet_report_conversion();
		}

		$.ajax({
			type: 'POST',
			url: easy_ajax_object.ajax_url,
			data: {
				action: 'figure_check',
				firstname: firstname,
				age: age,
				size: size,
				weight: weight,
				goal: goal,
				health: health,
				intolerances_true_false: intolerances_true_false,
				intolerances: intolerances,
				diets: diets,
				weight_goal: weight_goal,
				preference: preference,
				nonce: easy_ajax_object.nonce,
			},
		}).done(function(data) {
			// console.log('ajax appointment request: done');
			// console.log('data');

			//	get the json from the ajax response and split it up in to separate vars
			//	var data_all = JSON.parse(data);
			//	var name = data_all.response;
			//	var response_headline = data_all.response_headline;
			//	var response_copy = data_all.response_copy;

			// only for testing only dev
			// console.log('data: ' + data);

			// history.pushState(null, 'Figur Check Ergebnis', '/figurcheck-ergebnis');

			setTimeout(function() {
				$('.page-head').remove();
				$('#figure-check').remove();
				$('html, body').animate({ scrollTop: 0 }, '300');
				$('#figure-check-result').html(data);
			}, 5000);
		});
	}
	// -------------------------------------------------------------------------------

	// add a checked class to the label
	$('#figure-check').on('change', '#intolerances-false, #intolerances-true', function() {
		if ($(this).val() === 'true') {
			$('#step-7 .select-boxes-wrapper').removeClass('sub-step-hidden');
		} else {
			$('#step-7 .select-boxes-wrapper').addClass('sub-step-hidden');
		}

		$(this)
			.parent()
			.toggleClass('checked');

		$(this)
			.parent()
			.siblings()
			.removeClass('checked');
	});

	// ---------------------------------------------------------------------------------------------
	// add class when any radio button or checkbox is checked in the current step
	$('#figure-check').on('change', '#step-6 input[type="radio"], #step-5 input[type="radio"], #step-10 input[type="radio"]', function() {
		$('.step-active input:checked')
			.parent()
			.removeClass('is-not-checked');
		$('.step-active input:not(:checked)')
			.parent()
			.addClass('is-not-checked');
	});

	// ---------------------------------------------------------------------------------------------

	// add a checked class to the label and check the input
	$('#figure-check .select-boxes-wrapper').on('click', 'label', function() {
		$(this).toggleClass('checked');
		if (
			$(this)
			.find('input')
			.is(':checked')
		) {
			$(this)
				.find('input')
				.prop('checked', false);
		} else {
			$(this)
				.find('input')
				.prop('checked', true);
		}

		// validation
		var figure_check_valid = figure_check_validation($(this).closest('fieldset'));
		if (figure_check_valid === true) {
			$(this)
				.closest('fieldset')
				.find('.button')
				.removeClass('blocked');
		}
	});

	// ---------------------------------------------------------------------------------------------
	// set cursor in the in put by click on the label
	$('#figure-check #firstname').focus();

	$('#figure-check').on('click', 'label', function() {
		$(this)
			.find('input')
			.focus();
	});

	// set cursor in the in put by click on the headline
	$('#figure-check').on('click', 'h2', function() {
		$(this)
			.closest('.step-wrapper')
			.find('input')
			.focus();
	});

	$('#step-4').on('click', '.scales', function() {
		$(this)
			.find('input')
			.focus();
	});

	// --------------------------------------------------------------------------------------------

	function figure_check_progress() {
		var active_id = $('#figure-check .step-active')
			.closest('fieldset')
			.attr('id');

		switch (active_id) {
			case 'step-1':
				$('#figure-check .achieved').attr('data-achieved', 5);
				$('#figure-check .percentage').text('5%');
				break;
			case 'step-2':
				$('#figure-check .achieved').attr('data-achieved', 10);
				$('#figure-check .percentage').text('10%');
				break;
			case 'step-3':
				$('#figure-check .achieved').attr('data-achieved', 20);
				$('#figure-check .percentage').text('20%');
				break;
			case 'step-4':
				$('#figure-check .achieved').attr('data-achieved', 30);
				$('#figure-check .percentage').text('30%');
				break;
			case 'step-5':
				$('#figure-check .achieved').attr('data-achieved', 40);
				$('#figure-check .percentage').text('40%');
				break;
			case 'step-6':
				$('#figure-check .achieved').attr('data-achieved', 50);
				$('#figure-check .percentage').text('50%');
				break;
			case 'step-7':
				$('#figure-check .achieved').attr('data-achieved', 60);
				$('#figure-check .percentage').text('60%');
				break;
			case 'step-8':
				$('#figure-check .achieved').attr('data-achieved', 70);
				$('#figure-check .percentage').text('70%');
				break;
			case 'step-9':
				$('#figure-check .achieved').attr('data-achieved', 80);
				$('#figure-check .percentage').text('80%');
				break;
			case 'step-10':
				$('#figure-check .achieved').attr('data-achieved', 90);
				$('#figure-check .percentage').text('90%');
				break;
			case 'step-11':
				$('#figure-check .achieved').attr('data-achieved', 100);
				$('#figure-check .percentage').text('100%');
				break;

			default:
				$('#figure-check .achieved').attr('data-achieved', 100);
				$('#figure-check .percentage').text('100%');
		}
	}

	// --------------------------------------------------------------------------------------------

	function figure_check_validation_check_message(input_element) {
		var figure_check_valid = false;

		var message = $(input_element).data('error-message');
		// console.log(message);

		if ($(input_element).attr('type') === 'text' || $(input_element).attr('type') === 'number') {
			var figure_check_valid = false;

			// check if text and number fields has a valid value
			// console.log('val' + $(input_element).val());

			if (!$(input_element).val()) {
				figure_check_valid = false;
			} else {
				figure_check_valid = true;

				// extra validation for the name
				if (input_element === '#firstname') {
					// console.log('name check start');
					var name_regex = /^[a-zA-Z\s-äöüÄÖÜßéèËëÏïÀàÁáÂâÇçÈèÉéÊêÑñÒòÓóÔôõŸÿ–— ]*$/g;
					var name_regex = /^[A-Za-z-ÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ-–— ]{2,30}$/g;
					var val = $(input_element).val();

					if (val.match(name_regex)) {
						//console.log('name check: valid');
						figure_check_valid = true;
					} else {
						//console.log('name check: not valid');
						figure_check_valid = false;
					}
				}

				// extra validation for the age size and weight
				if (input_element === '#age' || input_element === '#size' || input_element === '#weight' || input_element === '#weight-goal') {
					var min = parseInt($(input_element).attr('min'));
					var max = parseInt($(input_element).attr('max'));
					var val = $(input_element).val();

					// console.log('num range check: min=' + min + ' max=' + max + ' val=' + val);

					if (val >= min && val <= max) {
						// console.log('num range check: valid');
						figure_check_valid = true;
					} else {
						// console.log('num range check: not valid');
						figure_check_valid = false;
					}
				}

				if (input_element === '#diets') {
					var val = $(input_element).val();
					//console.log('diet empty check 1');

					if (val >= 0) {
						//console.log('diet empty check 1.1');
						figure_check_valid = true;
					} else {
						//	console.log('diet empty check 1.2x');
						figure_check_valid = false;
					}
				}
			}
		} else if ($(input_element).attr('type') === 'checkbox' || $(input_element).attr('type') === 'radio') {
			// check if radiobuttons or checkboxes have a selection

			if (
				$(input_element).attr('name') != 'intolerances' ||
				$(input_element)
				.closest('fieldset')
				.find('#intolerances-true')
				.prop('checked') == true
			) {
				// console.log('intolerances-true checked');

				if ($(input_element).is(':checked')) {
					figure_check_valid = true;
				} else {
					figure_check_valid = false;
				}
			} else if (
				$(input_element).attr('name') != 'intolerances' ||
				$(input_element)
				.closest('fieldset')
				.find('#intolerances-false')
				.prop('checked') == true
			) {
				// console.log('intolerances-false checked');
				figure_check_valid = true;
			}
		}

		if (!$(input_element).val() && input_element === '#diets') {
			var val = $(input_element).val();
			console.log('diet empty check 2: ' + $(input_element).attr('value'));
			if (val === null || val === undefined || val === '') {
				// console.log('diet empty check 2.1');
				figure_check_valid = true;
			} else {
				// console.log('diet empty check 2.2');
				figure_check_valid = false;
			}
		}

		// ----------------------------------------------------------------------------------------

		if (figure_check_valid == true) {
			$(input_element)
				.parent()
				.removeClass('error-message');
			$(input_element)
				.parent()
				.find('.error-message')
				.remove();

			$(input_element)
				.closest('fieldset')
				.find('.next, .back')
				.removeClass('blocked');
		} else {
			$(input_element)
				.parent()
				.addClass('error-message');

			$(input_element)
				.parent()
				.append('<span class="error-message">' + message + '</span>');

			$(input_element)
				.closest('fieldset')
				.find('.next, .back')
				.addClass('blocked');
		}

		// console.log('figure_check_validation_check_message ' + figure_check_valid);

		return figure_check_valid;
	}

	function figure_check_validation(input_element) {
		var active_id = $('#figure-check .step-active').index();
		active_id = 'step-' + active_id;

		var figure_check_valid = false;

		switch (active_id) {
			case 'step-1':
				figure_check_valid = figure_check_validation_check_message('#firstname');
				break;
			case 'step-2':
				figure_check_valid = figure_check_validation_check_message('#age');
				break;
			case 'step-3':
				figure_check_valid = figure_check_validation_check_message('#size');
				break;
			case 'step-4':
				figure_check_valid = figure_check_validation_check_message('#weight');
				break;
			case 'step-5':
				figure_check_valid = figure_check_validation_check_message('input[name=goal]');
				break;
			case 'step-6':
				figure_check_valid = figure_check_validation_check_message('input[name=health]');
				break;
			case 'step-7':
				figure_check_valid = figure_check_validation_check_message('input[name=intolerances]');
				break;
			case 'step-8':
				figure_check_valid = figure_check_validation_check_message('#diets');
				break;
			case 'step-9':
				figure_check_valid = figure_check_validation_check_message('#weight-goal');
				break;
			case 'step-10':
				var figure_check_valid = figure_check_validation_check_message('input[name=preference]');
				break;
			case 'step-11':
				figure_check_valid = true;
				break;

			default:
				figure_check_valid = false;
		}

		//console.log('active_id ' + active_id + 'figure_check_validation ' + figure_check_valid);

		return figure_check_valid;
	}

	//-----------------------------------------------------------------------------

	// scroll to to the progress bar
	function figure_check_scroll_top() {
		var headerHeight = $('header').outerHeight();
		var $targetEle = $('.progress');
		$('html, body').animate({ scrollTop: $targetEle.offset().top - headerHeight - 28 }, 300, 'linear');
	}
	//-----------------------------------------------------------------------------

	function figure_check_valid_is_true(input_element) {
		$('.page-head .copy-wrapper').hide();

		$(input_element)
			.closest('fieldset')
			.addClass('step-hidden')
			.removeClass('step-active');
		$(input_element)
			.closest('fieldset')
			.next('fieldset')
			.addClass('step-active')
			.removeClass('step-hidden');

		$(input_element)
			.closest('fieldset:not(#step-10)')
			.next('fieldset')
			.find('input[type=text], input[type=number]')
			.first()
			.focus();
	}

	$('#figure-check').on('click', 'button.next', function(e) {
		e.preventDefault(e);

		var figure_check_valid = figure_check_validation($(this).closest('fieldset'));
		// console.log('valid: ' + figure_check_valid);

		if (figure_check_valid === true) {
			figure_check_valid_is_true($(this));

			// scroll to to the progress bar
			figure_check_scroll_top();

			figure_check_progress();
		}
	});

	$('#figure-check').on('keypress', 'input[type=text], input[type=number], input[type=checkbox], input[type=radio]', function(e) {
		//console.log('keypress');

		var keyCode = e.keyCode || e.which;

		//console.log('keyCode: ' + keyCode);

		// allow only typing number chars 0-9 and pass the return key
		if (keyCode !== 13 && $(this).is('input[type=number]')) {
			// console.log('number and not 13');

			// allow only typing number chars 0-9
			if (String.fromCharCode(keyCode).match(/[^0-9]/g)) {
				return false;
			}
		}

		if (keyCode === 13) {
			e.preventDefault();

			var figure_check_valid = figure_check_validation($(this).closest('fieldset'));
			// console.log('valid: ' + figure_check_valid);

			if (figure_check_valid === true) {
				figure_check_valid_is_true($(this));

				// scroll to to the progress bar
				figure_check_scroll_top();

				figure_check_progress();
			}
		}
	});

	// extra enter key event function for step 7

	$(document).keydown(function(keyevent) {
		var keyCode = keyevent.keyCode || keyevent.which;

		// console.log('press key: ' + keyCode);
		if (keyCode === 13 && $('#step-7').hasClass('step-active')) {
			keyevent.preventDefault();

			// console.log('press key ' + keyCode + 'in step 7');

			var figure_check_valid = figure_check_validation($('#step-7 input[name=intolerances]'));
			//console.log('valid: ' + figure_check_valid);

			if (figure_check_valid === true) {
				// console.log('step 7 by key is: ' + figure_check_valid);

				$('#step-7')
					//.closest('fieldset')
					.addClass('step-hidden')
					.removeClass('step-active');

				$('#step-8')
					.addClass('step-active')
					.removeClass('step-hidden')
					.find('input[type=text], input[type=number], input[name=intolerances_true_false]')
					.focus();

				// scroll to to the progress bar
				figure_check_scroll_top();

				figure_check_progress();
			}
		}
	});

	// --------------------------------------------------------------------------------------------

	$('#figure-check').on('click', '.back:not(.blocked)', function(e) {
		e.preventDefault(e);
		//console.log('valid: ' + figure_check_valid);

		$(this)
			.closest('fieldset')
			.addClass('step-hidden')
			.removeClass('step-active');
		$(this)
			.closest('fieldset')
			.prev('fieldset')
			.addClass('step-active')
			.removeClass('step-hidden');

		$(this)
			.closest('fieldset:not(#step-10)')
			.prev('fieldset')
			.find('input[type=text], input[type=number]')
			.first()
			.focus();

		// scroll to to the progress bar
		figure_check_scroll_top();

		figure_check_progress();
	});

	// ---------------------------------------------------------------------------------------------

	$('#figure-check .error-message').on('blur', 'input[type="text"], input[type="number"] ', function() {
		// console.log('check on blur');

		if ($(this).attr('name') == 'intolerances_true_false') {
			figure_check_valid === true;
		} else {
			var figure_check_valid = figure_check_validation($(this).closest('fieldset'));
		}
		//console.log('figure_check_valid ' + figure_check_valid);

		if (figure_check_valid === true) {
			$(this)
				.closest('fieldset')
				.find('.button')
				.removeClass('blocked');
		}
	});

	$('#figure-check').on('change', 'input[type="radio"], input[type="checkbox"]', function() {
		// console.log('check on change ');

		if ($(this).attr('name') == 'intolerances_true_false') {
			if ($('.select-boxes-wrapper label').hasClass('error-message')) {
				var figure_check_valid = figure_check_validation($(this).closest('fieldset'));
			} else {
				figure_check_valid === true;
			}
		} else {
			var figure_check_valid = figure_check_validation($(this).closest('fieldset'));
		}

		//console.log('figure_check_valid ' + figure_check_valid);

		if (figure_check_valid === true) {
			$(this)
				.closest('fieldset')
				.find('.button')
				.removeClass('blocked');
		}
	});

	$('#figure-check').on('input', '.error-message input', '.error-message input[type="text"], .error-message input[type="number"]', function() {
		// console.log('check on input');

		if ($(this).attr('name') == 'intolerances_true_false') {
			figure_check_valid === true;
		} else {
			var figure_check_valid = figure_check_validation($(this).closest('fieldset'));
		}

		//console.log('figure_check_valid ' + figure_check_valid);

		if (figure_check_valid === true) {
			$(this)
				.closest('fieldset')
				.find('.button')
				.removeClass('blocked');
		}
	});

	//-----------------------------------------------------------------------------

	// ------------------------------------------------------------------------------------------------
	// figure check submit

	function figure_check_pre_submit() {
		var firstname = $("input[name~='firstname']").val();
		var age = $("input[name='age']").val();
		var size = $("input[name='size']").val();
		var weight = $("input[name='weight']").val();

		var goal = new Array();
		$("input[name='goal']:checked").each(function() {
			goal.push(this.value);
		});

		var health = $("input[name='health']:checked").val();

		var intolerances_true_false = $("input[name='intolerances_true_false']:checked").val();
		if (intolerances_true_false == 'true') {
			var intolerances = new Array();
			$("input[name='intolerances']:checked").each(function() {
				intolerances.push(this.value);
			});
		}

		var diets = $("input[name='diets']").val();
		var weight_goal = $("input[name='weight_goal']").val();
		var preference = $("input[name='preference']:checked").val();

		figure_check(firstname, age, size, weight, goal, health, intolerances_true_false, intolerances, diets, weight_goal, preference);
	}

	$('#figure-check').on('click', '.submit', function(event) {
		event.preventDefault();
		figure_check_pre_submit();
	});

	// allow a submit on press enter
	$('#figure-check input[name=preference]').on('keyup keypress', '', function(e) {
		var keyCode = e.keyCode || e.which;
		if (keyCode === 13) {
			e.preventDefault();
			figure_check_pre_submit();
		}
	});

	//-------------------------------------------------------------------------------------------------------------------------

	// add the link text as data value to prevent hover flickering…
	$('#main-nav li a, #mobile-nav li a').each(function() {
		var linktext = $(this).text();
		$(this).attr('data-linktext', linktext);
	});

	//-------------------------------------------------------------------------------------------------------------------------

	//-------------------------------------------------------------------------------------------------------------------------
	// gallery
	// Magnific Popup
	// https://dimsemenov.com/plugins/magnific-popup/documentation.html

	$('.gallery-wrapper').magnificPopup({
		delegate: 'figure',
		type: 'image',
		// other options

		image: {
			markup: '<div class="mfp-figure">' +
				'<div class="mfp-close"></div>' +
				'<div class="mfp-img"></div>' +
				'<div class="mfp-bottom-bar">' +
				// '<div class="mfp-title"></div>'+
				'<div class="mfp-counter"></div>' +
				'</div>' +
				'</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

			cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

			titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
			// Or the function that should return the title. For example:
			// titleSrc: function(item) {
			//   return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
			// }

			verticalFit: true, // Fits image in area vertically

			tError: 'Das Bild konnte nicht geladen werden.', // Error message
		},

		// ----

		gallery: {
			enabled: true, // set to true to enable gallery
		},
	});

	$.extend(true, $.magnificPopup.defaults, {
		tClose: 'schließen (ESC)', // Alt text on close button
		tLoading: 'Bild laden…', // Text that is displayed during loading. Can contain %curr% and %total% keys
		gallery: {
			tPrev: 'zurück', // Alt text on left arrow
			tNext: 'weiter', // Alt text on right arrow
			tCounter: '%curr% / %total%', // Markup for "1 of 7" counter
		},
		image: {
			tError: 'Das Bild konnte nicht geladen werden.', // Error message when image could not be loaded
		},
	});

	//-------------------------------------------------------------------------------------------------------------------------
	// all resize functions
	//-------------------------------------------------------------------------------------------------------------------------

	var resizeTimer;
	$(window).on('resize', function(e) {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {
			classMobile();
			floatingLabelPaddingWidth();
		}, 300);
	});

	// -------------------------------------------------------------------------------------
	// init AOS animations
	// https://michalsnik.github.io/aos/
	// https://github.com/michalsnik/aos/tree/v2
	AOS.init({
		disable: 'mobile',
	});
	// -------------------------------------------------------------------------------------

	// -------------------------------------------------------------------------------------
	// fix for everrest goolge places review plugin

	/*  $('.egpr-read-more').click(function () {
				$(this).closest('.egpr-small-description').slideUp(400);
				$(this).closest('.egpr-content-reviews-wrapper').find('.egpr-full-description').slideDown(400);
				return false;
		});

	 $('.egpr-read-less').click(function () {
				$(this).closest('.egpr-full-description').slideUp(400);
				$(this).closest('.egpr-content-reviews-wrapper').find('.egpr-small-description').slideDown(400);
				return false;
		}); */
	//$('.egpr-read-more').click(function() {
	$(document).on('click', '.egpr-read-more', function() {
		$(this)
			.closest('.egpr-small-description')
			.slideUp(400);
		$(this)
			.closest('.egpr-content-reviews-wrapper')
			.find('.egpr-full-description')
			.slideDown(400);
		return false;
	});

	/** Read less Excerpt Control **/
	//	$('.egpr-read-less').click(function() {
	$(document).on('click', '.egpr-read-less', function() {
		$(this)
			.closest('.egpr-full-description')
			.slideUp(400);
		$(this)
			.closest('.egpr-content-reviews-wrapper')
			.find('.egpr-small-description')
			.slideDown(400);
		return false;
	});

	// -------------------------------------------------------------------------------------
	// })(jQuery);
});

// for store locator plugin --------------------------------------------------------------
function asl_event_hook(_event) {
	if (_event.type == 'init') {
		asl_configuration.is_mob = false;
	}
}

// ---------------------------------------------------------------------------------------